/* eslint-disable no-console,no-undef */
import React from 'react';
import { MainContainerNew } from './styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {getUpComingChallenge} from '../../../redux/actions';
import { isEmpty, isNull, isUndefined } from 'lodash';
import UpComingChallengesSocialSub from './challengeSocialFeedSub';
import { withTranslation } from 'react-i18next';

class UpComingChallengesSocial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeArray: []
    };
  }

  componentDidMount() {
    const {getUpComingChallenge } = this.props;
    getUpComingChallenge();
  }

  render() {
    const {  history, padding,upComingChallenge, t, adminChallenge } = this.props;
    let activeChallengeShow = [];
    if (!isEmpty(upComingChallenge) && upComingChallenge && upComingChallenge.length > 0) {
      activeChallengeShow = upComingChallenge[0];
    }

    return (
      <div>
        {
          activeChallengeShow
            ?
            <MainContainerNew adminChallenge={adminChallenge?1:0} paddingValue={padding === '1' ? '25px 25px 0px 0px' : (padding === '3' && activeChallengeShow.length>0) ? '0px 0px 25px 0px' : padding === '4' ? '25px 0px 0px 0px': '0px 0 0px 0'}>
              <div>
                <div>
                  {upComingChallenge&& upComingChallenge.length >= 1 ? (padding===4?t("Upcoming Company Challenges"):t("Upcoming Challenges")) : ''}
                </div>
                {upComingChallenge&& upComingChallenge.length > 1 &&
                  <div onClick={() => history.push('/challenges')}>
                    {t("View All")}
                  </div>}
              </div>
              {isEmpty(activeChallengeShow) || isNull(activeChallengeShow) || isUndefined(activeChallengeShow)
                ? '':
                <UpComingChallengesSocialSub activeChallengeShow={activeChallengeShow} history={history} padding={padding}/>
              }
            </MainContainerNew>
            : null}
      </div>
    )
  }
}
UpComingChallengesSocial.propTypes = {
  history: PropTypes.object.isRequired,
  padding: PropTypes.string,
  getUpComingChallenge: PropTypes.func,
  upComingChallenge: PropTypes.array,
  t: PropTypes.func,
  adminChallenge: PropTypes.string
};
const mapStateToProps = (state) => ({
  upComingChallenge: state.wellnessDashboard.upComingChallenge

})
const mapDispatchToProps = (dispatch) => ({
  getUpComingChallenge: () => dispatch(getUpComingChallenge()),

})
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UpComingChallengesSocial));