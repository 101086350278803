/* eslint-disable no-console,no-undef */
import React from 'react';
import { MainContainer, SubContainer, ImageContainer, TextContainer, IconRight } from './styles';
import {
  convertDateInTimezone,
} from "../../../utils/methods";
import moment from "moment";
import PropTypes from 'prop-types';
import { ImageUrl } from '../../../utils/constants';
import { connect } from 'react-redux';
import { getActiveChallenge } from '../../../redux/actions';
import { isEmpty, isNull, isUndefined } from 'lodash';

class UpComingChallenges extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeArray: []
    };
  }

  componentDidMount() {
    const { getActiveChallenge } = this.props;
    getActiveChallenge();
  }

  render() {
    const { activeChallenge, history, padding } = this.props;
    let activeChallengeShow = [];
    if (!isEmpty(activeChallenge) && activeChallenge && activeChallenge.length > 0) {
      activeChallengeShow = activeChallenge[0];
    }
    return (
      <div>
        {activeChallengeShow?
          <MainContainer paddingValue={padding === 1 ? '25px 25px 0px 0px' : '0px 0 15px 0'}>
            <div>
              My Active Challenges
            </div>
            {isEmpty(activeChallenge) || isNull(activeChallenge) || isUndefined(activeChallenge)
              ? ''
              :
              activeChallenge.map((activeChallengeShow, index) => (
                <SubContainer onClick={() => history.push(`/challenges/${activeChallengeShow.id}`)} key={index}>
                  <div>
                    <ImageContainer>
                      {activeChallengeShow && activeChallengeShow.image ? <img src={`${ImageUrl}/${activeChallengeShow.image}`} /> : null}
                    </ImageContainer>
                    <TextContainer>
                      <span style={{ "font-size": "14px" }}>My Company Challenges</span>
                      <p style={{ "margin": "0px" }}>{activeChallengeShow && activeChallengeShow.title}</p>
                      <p style={{ "font-size": "14px", "margin": "0px" }}>{moment(convertDateInTimezone(activeChallengeShow && activeChallengeShow.start_date)._d).format('MMM DD, YYYY')}</p>
                    </TextContainer>
                    <IconRight>
                      <img src="/public/images/NewDashboardV2/arrowRight.png" alt="icon2" width="7px" height="16px" />
                    </IconRight>
                  </div>

                </SubContainer>))
            }
          </MainContainer>
          : null}
      </div>
    )
  }
}
UpComingChallenges.propTypes = {
  history: PropTypes.object.isRequired,
  getActiveChallenge: PropTypes.func,
  activeChallenge: PropTypes.object,
  padding: PropTypes.string
};
const mapStateToProps = (state) => ({
  activeChallenge: state.wellnessDashboard.activeChallenge,
})
const mapDispatchToProps = (dispatch) => ({
  getActiveChallenge: () => dispatch(getActiveChallenge()),
})
export default connect(mapStateToProps, mapDispatchToProps)(UpComingChallenges);