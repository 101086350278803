/* eslint-disable no-console,no-undef */
import React from 'react';
import { ImageContainerNew, SubContainerNew, TextContainerNew } from './styles';
import {
  convertDateInTimezone,
  getChallengeTimeToShow
} from "../../../utils/methods";
import moment from "moment";
import PropTypes from 'prop-types';
import { ImageUrl } from '../../../utils/constants';
import momentTZ from 'moment-timezone';
import { withTranslation } from 'react-i18next';

class UpComingChallengesSocialSub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: []
    };
  }

  componentDidMount() {
    const { activeChallengeShow } = this.props;
    this.setState({
      time: getChallengeTimeToShow(activeChallengeShow)
    })
  }

  render() {
    const { history, activeChallengeShow, padding, t } = this.props;
    const { time } = this.state;
    const DaysLeft = time.length > 0 && time.map((step) => step);
    return (
      <SubContainerNew onClick={() => history.push(`/challenges/${activeChallengeShow.id}`)} height={"104px"} margin={padding?"15px 0 25px 0":'15px 0 0 0'}>
        <div>
          <ImageContainerNew>
            {activeChallengeShow && activeChallengeShow.image ? <img src={`${ImageUrl}/${activeChallengeShow.image}`} /> : null}
          </ImageContainerNew>
          <TextContainerNew>
            <p>{activeChallengeShow && activeChallengeShow.title}</p>
          </TextContainerNew>
        </div>
        <div>
          <div>
            <span>{t("Start Date")} : {moment(convertDateInTimezone(activeChallengeShow && activeChallengeShow.start_date)._d).format('MMM DD, YYYY')}</span>
          </div>
          {activeChallengeShow.challenge_status === "over" ? null :
            <div className="days">
              <span>{(DaysLeft === 'Starts Today' || DaysLeft === 'Last Day') ? DaysLeft : (convertDateInTimezone(activeChallengeShow.start_date)._d <= momentTZ()._d ? `${DaysLeft} ${t("Remaining")}` : `${DaysLeft} ${t("To Start")}`)}</span>
            </div>}
        </div>
      </SubContainerNew>
    )
  }
}
UpComingChallengesSocialSub.propTypes = {
  history: PropTypes.object.isRequired,
  activeChallenge: PropTypes.array,
  activeChallengeShow: PropTypes.array,
  padding: PropTypes.number,
  t: PropTypes.func
};

export default (withTranslation()(UpComingChallengesSocialSub));